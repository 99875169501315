<template>
  <div class="uk-width-1-1">
    <div class="uk-background-default uk-padding-small">
      <form class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label">Inhalt</label>
          <input type="text" class="uk-input" v-model="form.content" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Link</label>
          <input type="text" class="uk-input" placeholder="http://" v-model="form.link" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Link Ziel</label>
          <select class="uk-select" v-model="form.link_target">
            <option value="_self">Gleiches Fenster</option>
            <option value="_blank">Neues Fenster</option>
          </select>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Icon</label>
          <div class="uk-inline">
            <a
              class="uk-form-icon uk-form-icon-flip"
              href="#"
              uk-icon="icon: pencil"
              @click.prevent="openIconModal"
            ></a>
            <input type="text" class="uk-input" v-model="form.icon" />
          </div>
        </div>
        <div class="uk-margin" v-if="form.icon">
          <label class="uk-form-label">Icon Farbe</label>
          <select class="uk-select" v-model="form.icon_color">
            <option value>Keine</option>
            <option value="muted">Gedämpft</option>
            <option value="emphasis">Betont</option>
            <option value="primary">Primär</option>
            <option value="success">Erfolgt</option>
            <option value="warning">Warnung</option>
            <option value="danger">Achtung</option>
          </select>
        </div>
      </form>
    </div>
    <modal ref="selectIconModal" :large="true">
      <div class="uk-modal-header">
        <h3>Icon wählen</h3>
      </div>
      <div
        uk-overflow-auto
        class="uk-modal-body uk-overflow-auto"
        style="min-height: 150px; max-height: 450px;"
      >
        <div class="uk-grid-collapse uk-child-width-auto uk-grid" data-uk-grid>
          <div
            class="uk-card uk-card-body uk-card-small uk-card-hover uk-text-center"
            v-for="(icon, key) in icons"
            :key="key"
          >
            <span :data-uk-icon="'icon: ' + icon + ';ratio: 2'" class="uk-icon"></span>
            <a
              :title="icon"
              href="#"
              uk-tooltip="delay: 500"
              class="uk-position-cover"
              aria-expanded="false"
              @click.prevent="selectIcon(icon)"
            ></a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import axios from "axios";
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      form: {},
      icons: []
    };
  },
  mounted() {
    this.form = this.data.props;
    axios.get(`./../../../assets/json/icons.json`).then(response => {
      this.icons = response.data;
    });
  },
  methods: {
    openIconModal() {
      this.$refs.selectIconModal.open();
    },
    selectIcon(icon) {
      this.form.icon = icon;
      this.$refs.selectIconModal.close();
    }
  }
};
</script>
