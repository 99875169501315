<template>
  <ul id="element-settings" class="uk-switcher uk-margin">
    <li class="uk-active">
    <form class="uk-form-stacked" v-if="!editList">
      <div class="uk-width-1-1">
        <div class="uk-background-default uk-padding-small">
          <div class="uk-margin">
            <label class="uk-form-label">Liste</label>
            <div v-for="(item, index) in localData.items" :key="index" class="uk-margin-small">
              <div class="uk-flex uk-flex-middle">
                <button
                  type="button"
                  class="uk-button uk-flex uk-flex-middle uk-background-muted uk-text-left"
                  @click.prevent="edit(item)"
                >
                  {{ item.props.content }}
                  <span data-uk-icon="chevron-right"></span>
                </button>
                <a
                  href="#"
                  uk-icon="trash"
                  class="uk-margin-left uk-text-muted"
                  @click.prevent="removeItem(index)"
                ></a>
              </div>
            </div>
            <div class="uk-margin">
              <button
                class="uk-button-secondary uk-button uk-border-rounded uk-button-small"
                @click.prevent="add"
              >Eintrag hinzufügen</button>
            </div>
          </div>
          <hr />
          <div class="uk-grid uk-grid-divider uk-child-width-1-2@m" uk-grid>
            <div>
              <div class="uk-h5 uk-text-uppercase">Liste</div>
              <div class="uk-margin">
                <label class="uk-form-label">Style</label>
                <select class="uk-select uk-margin-small-bottom" v-model="form.list_style">
                  <option value>Ohne</option>
                  <option value="divider">Unterstrichen</option>
                  <option value="striped">Gestreift</option>
                  <option value="bullet">Punkte</option>
                </select>
                <label>
                  <input type="checkbox" class="uk-checkbox" v-model="form.list_items_padding" /> Mehr Abstand zwischen den Zeilen
                </label>
              </div>
              <div class="uk-h5 uk-text-uppercase">Link</div>
              <div class="uk-margin">
                <label class="uk-form-label">Style</label>
                <select class="uk-select uk-margin-small-bottom" v-model="form.list_link_style">
                  <option value>Ohne</option>
                  <option value="muted">Gedämpft</option>
                  <option value="text">Text</option>
                  <option value="reset">Reset</option>
                </select>
              </div>
            </div>
            <div>
              <div class="uk-h5 uk-text-uppercase">Inhalt</div>
              <div class="uk-margin">
                <label class="uk-form-label">Style</label>
                <select class="uk-select" v-model="form.list_item_style">
                  <option value>None</option>
                  <option value="text-bold">Fett</option>
                  <option value="text-muted">Gedämpft</option>
                  <option value="text-small">Klein</option>
                  <option value="h1">H1</option>
                  <option value="h2">H2</option>
                  <option value="h3">H3</option>
                  <option value="h4">H4</option>
                  <option value="h5">H5</option>
                  <option value="h6">H6</option>
                </select>
              </div>
              <div class="uk-h5 uk-text-uppercase">Icon</div>
              <div class="uk-margin">
                <label class="uk-form-label">Icon</label>
                <div class="uk-inline uk-width-1-1">
                  <a
                    class="uk-form-icon uk-form-icon-flip"
                    href="#"
                    uk-icon="icon: pencil"
                    @click.prevent="openIconModal"
                  ></a>
                  <input type="text" class="uk-input" v-model="form.list_icon" />
                </div>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Icon Farbe</label>
                <select class="uk-select" v-model="form.list_icon_color">
                  <option value>Keine</option>
                  <option value="muted">Gedämpft</option>
                  <option value="emphasis">Betont</option>
                  <option value="primary">Primär</option>
                  <option value="success">Erfolgt</option>
                  <option value="warning">Warnung</option>
                  <option value="danger">Achtung</option>
                </select>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Icon Größe</label>
                <input type="text" class="uk-input" v-model="form.list_icon_size" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="uk-width-1-1 uk-margin" v-else>
      <div class="uk-background-default uk-padding-small uk-box-shadow-medium">
        <list-item-edit :data="this.editListData" />
      </div>
    </div>
    <modal ref="selectIconModal" :large="true">
      <div class="uk-modal-header">
        <h3>Icon wählen</h3>
      </div>
      <div
        uk-overflow-auto
        class="uk-modal-body uk-overflow-auto"
        style="min-height: 150px; max-height: 450px;"
      >
        <div class="uk-grid-collapse uk-child-width-auto uk-grid" data-uk-grid>
          <div
            class="uk-card uk-card-body uk-card-small uk-card-hover uk-text-center"
            v-for="(icon, key) in icons"
            :key="key"
          >
            <span :data-uk-icon="'icon: ' + icon + ';ratio: 2'" class="uk-icon"></span>
            <a
              :title="icon"
              href="#"
              uk-tooltip="delay: 500"
              class="uk-position-cover"
              aria-expanded="false"
              @click.prevent="selectIcon(icon)"
            ></a>
          </div>
        </div>
      </div>
    </modal>
    </li>
    <li>
      <div class="uk-width-1-1 uk-margin">
        <div>
          <general-settings :form="form" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import Modal from '@/components/Modal'
import axios from 'axios'
import ListItemEdit from './ListItemEdit'
import GeneralSettings from './../GeneralSettings'
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    ListItemEdit,
    GeneralSettings,
    Modal
  },
  data () {
    return {
      form: {},
      localData: {},
      editList: false,
      editListData: null,
      icons: [],
      showSettings: false
    }
  },
  mounted () {
    this.form = this.data.props
    this.localData = this.data
    axios.get('./../../../assets/json/icons.json').then(response => {
      this.icons = response.data
    })
  },
  methods: {
    async add () {
      const defaultProps = await axios.get(
        './../../../assets/json/defaultProps/listItem.json'
      )
      const elemObj = {
        type: 'list_item',
        props: defaultProps.data
      }
      this.localData.items.push(elemObj)
      this.edit(elemObj)
    },
    edit (item) {
      this.editList = true
      this.editListData = item
    },
    removeItem (index) {
      this.localData.items.splice(index, 1)
    },
    openIconModal () {
      this.$refs.selectIconModal.open()
    },
    selectIcon (icon) {
      this.form.list_icon = icon
      this.$refs.selectIconModal.close()
    }
  }
}
</script>
